.content {
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 80px;
}

.card-list {
  min-height: 70px;
}

.card-list-lg {
  min-height: 110px;
}

.card-list img {
  margin-right: 15px;
  margin-bottom: 15px;
  float: left;
}

.card-list-lg img {
  width: 80px;
  height: 80px;
}

.card-list > div > div:nth-child(2) {
  font-size: 14px;
  color: #777;
}

.muted {
  color: #555;
  font-size: 14px;
}

#ImageZoom img {
  width: 100%;
}

#product img,
#order img,
#CalculatorPage img,
#TradeableGuide img {
  width: 100%;
}

#product p {
  margin: 8px;
}

#product ul {
  margin-top: 0;
}

.form-group {
  padding: 12px 0;
}

.text-input {
  font-size: 18px;
}

.toolbar,
.button {
  background-color: #004165;
  background-image: none;
}

.toolbar__title,
.back-button__label,
.back-button__icon {
  color: white;
  fill: white;
}

#product .img-list {
  width: 50%;
}
